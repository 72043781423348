import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import * as routes from '../utils/routes';
import "../index.css";
import apiClient from '../services/apiClient';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '../assets/svgs/DashboardIcon';
import ProductIcon from '../assets/svgs/ProductIcon';
import ExpenseIcon from '../assets/svgs/ExpenseIcon';
import OrderIcon from '../assets/svgs/OrderIcon';
import InventoryIcon from '../assets/svgs/InventoryIcon';
import LogoutIcon from '../assets/svgs/LogoutIcon';
import { CurrencyCode } from '../types/currencies';
import currencies from '../utils/currencies.json';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../types';
import { updateCurrency } from '../redux/actions';
import { CircularProgress } from '@mui/material';

const Bar = styled.div<{ $isOpen: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    background-color: #1E2129;
    width: 250px;
    min-height: 100vh;
    overflow:auto;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    padding-top: 40px;
    box-sizing: border-box;
    box-shadow: 0px 3px 4px 0px rgb(221 232 235 / 61%);
    border-bottom: 1px solid #dfedf3;
    z-index: 1;  /* Set lower than Header */
    transition: transform 300ms cubic-bezier(0.25, 1.3, 0.5, 1);

    @media (max-width: 768px) {
        transform: ${({ $isOpen }) => ($isOpen ? 'translateY(0)' : 'translateY(-100%)')};
        width: 100%;
    }

    @media (min-width: 768px) {
        transform: none; /* Always visible for larger screens */
    }
`;

const Header = styled.header`
    position: fixed;
    width: 100vw;
    display: flex;
    z-index: 3;  /* Set higher than Bar */
    @media (min-width: 768px) {
        display: none; /* Hide button for larger screens */
    }
`;

const ToggleButton = styled.button`
    z-index: 1000;
    color: black;
    border: 1px solid #616265;
    border-radius:20px;
    padding: 6px 12px;
    cursor: pointer;

    @media (min-width: 768px) {
        display: none; /* Hide button for larger screens */
    }
`;

const Logo = styled.p`
    font-size: 30px;
    margin-bottom: 50px;
    font-family: 'Noto Sans', sans-serif;
    font-family: 'Red Hat Display', sans-serif;
    color: #F6F8F7;
    @media (max-width: 768px) {
        display: none; /* Hide logo for smaller screens */
    }
`;

const NavItems = styled.div`
    display: flex;
    flex-direction: column;
`;

const IconContainer = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  svg {
    fill: ${({ $isActive }) => ($isActive ? '#3CB2FE' : '#7E818B')};
  }
`;

const NavItem = styled.div<{ $isActive: boolean }>`
    display:flex;
    flex-direction:row;
    align-items:center;
    font-size: 14px;
    text-decoration: none;
    color: ${props => (props.$isActive ? '#3CB2FE' : '#7E818B')};
    background-color: ${props => (props.$isActive ? '#223C53' : 'transparent')};
    padding: 15px 25px;
    margin-bottom: 5px;
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
    transition: background-color 200ms, color 100ms;

    &:hover {
        color: #3CB2FE;

        ${IconContainer} svg {
            fill: #3CB2FE;
        }
    }
`;

const CurrencySelector = styled.select`
    background-color: #1e2128;
    color: #7E818B;
    border: 1px solid #454950;;
    border-radius: 10px;
    padding: 8px 15px;
    margin: 20px 0px;
    font-size: 14px;
    cursor: pointer;
    outline: none;

    &:hover {
        border-color: #3CB2FE;
        color: #3CB2FE;
    }

    option {
        background-color: #1E2129;
        color: #7E818B;
    }
`;

const LogoutButton = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    font-size: 14px;
    text-decoration: none;
    color: #7E818B;
    padding: 15px 25px;
    margin-bottom: 5px;
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
    transition: background-color 200ms, color 100ms;
    user-select: none;
    cursor: pointer;

    &:hover {
        color: #3CB2FE;

        ${IconContainer} svg {
            fill: #3CB2FE;
        }
    }
`;

const styles = {
    logoLink: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '40px',
        textDecoration: 'none',
        fontSize: '35px',
        color: 'black'
    },
    link: {
        fontSize: '14px',
        textDecoration: 'none',
        color: '#7E818B'
    }
};

interface NavItemProp {
    label: string;
    route: string;
    icon?: any;
}

export default function NavBar() {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState<CurrencyCode>(CurrencyCode.USD);
    const [isUpdatingCurrency, setIsUpdatingCurrency] = useState(false);

    const location = useLocation();
    const dispatch = useDispatch();

    const currency = useSelector((state: RootState) => state.auth.user?.currency);

    useEffect(() => {
        setSelectedCurrency(currency || CurrencyCode.USD);
    }, [currency])

    const navSections = [
        [{ label: "Dashboard", route: routes.DASHBOARD, icon: DashboardIcon }],
        [{ label: "Products", route: routes.PRODUCTS, icon: ProductIcon }, { label: "Inventory", route: routes.INVENTORY, icon: InventoryIcon }, { label: "Orders", route: routes.ORDERS, icon: OrderIcon }],
        [{ label: "Expenses", route: routes.EXPENSES, icon: ExpenseIcon }]
    ];

    const handleLogout = () => {
        apiClient.auth.logout();
    };

    const toggleNavBar = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = () => {
        if (window.innerWidth <= 768) {
            setIsOpen(false);  // Close the NavBar on small screens
        }
    };

    const handleCurrencyChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newCurrency = event.target.value as CurrencyCode;
        setSelectedCurrency(newCurrency);

        try {
            setIsUpdatingCurrency(true);
            apiClient.update(`/users/update-currency?currency=${newCurrency}`, null, true)
                .then(() => {
                    dispatch(updateCurrency(newCurrency));

                    console.log('Currency updated successfully');
                })
                .finally(() => setIsUpdatingCurrency(false));
        } catch (error) {
            console.error('Failed to update currency:', error);
            // Handle error appropriately - maybe show a notification
        }
    };

    return (
        <>
            <Header className="p-2 flex justify-between items-center" style={{ backgroundColor: '#1E2129', padding: '10px 30px' }}>
                <div className="text-white" style={{ fontWeight: 'bold', fontSize: '19px' }}>Vend<span style={{ color: '#3a5bd9' }}>Ready</span></div>
                <ToggleButton className="text-black font-bold py-2 px-4 rounded" onClick={toggleNavBar}>
                    {isOpen ? (<>
                        <CloseIcon style={{ color: 'white', fontSize: '20px' }} />
                        <span style={{ color: 'white', fontWeight: '200', fontSize: '13px', marginLeft: '6px' }}>Close</span>
                    </>) : (<>
                        <MenuIcon style={{ color: 'white', fontSize: '20px' }} />
                        <span style={{ color: 'white', fontWeight: '200', fontSize: '13px', marginLeft: '6px' }}>Menu</span>
                    </>)}
                </ToggleButton>
            </Header>

            <Bar $isOpen={isOpen}>
                <Link to={routes.DASHBOARD} style={styles.logoLink} onClick={handleLinkClick}>
                    <Logo>Vend<span style={{ color: '#3a5bd9' }}>Ready</span></Logo>
                </Link>
                <NavItems>
                    {navSections.map((section: NavItemProp[], index: number) => (
                        <div key={index}>
                            {section.map((navItem: NavItemProp) => {
                                const IconComponent = navItem.icon;
                                const isActive = location.pathname === navItem.route;

                                return (
                                    <React.Fragment key={navItem.route}>
                                        <Link
                                            to={navItem.route}
                                            style={styles.link}
                                            onClick={handleLinkClick}  // Close the NavBar after click
                                        >
                                            <NavItem $isActive={isActive}>
                                                <IconContainer $isActive={isActive}>
                                                    <IconComponent />
                                                </IconContainer>
                                                <span style={{ marginLeft: '10px' }}>{navItem.label}</span>
                                            </NavItem>
                                        </Link>
                                    </React.Fragment>
                                )
                            })
                            }
                            <div style={{ margin: '15px auto', height: '1px', backgroundColor: '#7E818B', width: '70%', opacity: '0.4' }}></div>
                        </div>
                    ))}
                </NavItems>
                {
                    isUpdatingCurrency ? (
                        <div style={{
                            color: "#7E818B", border: "1px solid #454950",
                            borderRadius: ' 10px',
                            padding: '7px 15px',
                            margin: '20px 0px',
                            display: 'flex',
                            justifyContent: 'center',
                            borderColor: "#3CB2FE"
                        }}>
                            <CircularProgress size={20.5} />
                        </div>
                    ) : (
                        <CurrencySelector value={selectedCurrency} onChange={handleCurrencyChange}>
                            {currencies.map((currency) => (
                                <option key={currency.cc} value={currency.cc}>
                                    {currency.cc} - {currency.name}
                                </option>
                            ))}
                        </CurrencySelector>
                    )
                }
                <LogoutButton onClick={handleLogout}>
                    <IconContainer $isActive={false}>
                        <LogoutIcon />
                    </IconContainer>
                    <span style={{ marginLeft: '10px' }}>Logout</span>
                </LogoutButton>
            </Bar>
        </>
    );
}
