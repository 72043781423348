import { GoogleLogin } from '@react-oauth/google';
import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import apiClient from '../services/apiClient';
import * as routes from '../utils/routes';
import { RegisterFields } from '../types';
import { CurrencyCode } from '../types/currencies';
import currencies from '../utils/currencies.json';
import ReactGA from 'react-ga4';

const initialState: RegisterFields = {
    firstname: '',
    surname: '',
    email: '',
    password: '',
    confirmPassword: '',
    shop_name: '',
    currency: CurrencyCode.USD
};

const Register = () => {
    const [step, setStep] = React.useState<'initial' | 1 | 2>('initial');
    const [registerFields, setRegisterFields] = React.useState<RegisterFields>(initialState);
    const [isPosting, setIsPosting] = React.useState(false);

    const navigate = useNavigate();

    React.useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/register",
            title: "Register",
        });
    }, []);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (step === 1) {
            setStep(2);
            return;
        }
        setIsPosting(true);

        apiClient.auth.register(registerFields)
            .then(() => {
                setIsPosting(false);
                navigate(routes.DASHBOARD);
            })
            .catch(() => {
                setIsPosting(false);
            });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setRegisterFields({ ...registerFields, [e.target.name]: e.target.value });
    };

    const handleGoogleSignUp = (response: any) => {
        // Implement Google sign up
        console.log('Google sign up', response);
        apiClient.auth.loginViaGoogle(response)
            .then(() => {
                navigate(routes.DASHBOARD);
            })
    };

    const handleEmailSignUp = () => {
        setStep(1);
    };

    const benefits = [
        { icon: '🛡️', text: 'No credit card required' },
        { icon: '🏪', text: 'Perfect for small to medium businesses' },
        { icon: '📊', text: 'Track inventory in real-time' },
        { icon: '📱', text: 'Access from any device, anywhere' },
    ];

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="absolute top-8 left-8 md:fixed">
                <Link to="/" className="flex items-center gap-2">
                    {/* <div className="bg-blue-600 text-white w-8 h-8 md:w-10 md:h-10 rounded-lg flex items-center justify-center font-bold text-lg md:text-xl">V</div> */}
                    <span className="text-xl md:text-2xl font-bold text-gray-900" style={{ fontSize: '1.8rem' }}>Vend<span style={{ color: '#3a5bd9' }}>Ready</span></span>
                </Link>
            </div>

            <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-center gap-12 md:mt-20 md:mt-0" style={{ width: '100%' }}>
                <div className="lg:w-1/2 hidden md:block" style={{ padding: '25px' }}>
                    <h1 className="text-3xl md:text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl mb-6">
                        Simplify Your Business Operations
                    </h1>
                    <p className="text-lg md:text-xl text-gray-600 mb-12">
                        Join thousands of successful businesses who trust VendReady
                        for their inventory management needs.
                    </p>

                    <div className="space-y-4 hidden md:block">
                        {benefits.map((benefit, index) => (
                            <div
                                key={index}
                                className="flex items-center gap-4 p-4 bg-white/80 rounded-xl shadow-sm hover:translate-x-1 transition-transform duration-200"
                            >
                                <div className="w-10 h-10 md:w-12 md:h-12 flex items-center justify-center bg-blue-50 rounded-full flex-shrink-0">
                                    <span className="text-xl md:text-2xl">{benefit.icon}</span>
                                </div>
                                <span className="text-gray-700">{benefit.text}</span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="lg:w-1/2 w-full">
                    <div className="md:bg-white md:shadow-xl rounded-2xl p-6 sm:p-8 md:p-12 w-full max-w-xl mx-auto">
                        <h2 className="text-center text-2xl md:text-3xl font-bold text-gray-900 mb-2">
                            Create Your Account
                        </h2>
                        <p className="text-center text-gray-600 mb-8">
                            Get started with your free trial today
                        </p>

                        {step === 'initial' ? (
                            <div className="space-y-4" style={{ width: '350px', margin: '0px auto' }}>
                                <GoogleLogin
                                    width={350}
                                    onSuccess={handleGoogleSignUp}
                                    onError={() => {
                                        console.log("login failed");
                                    }}
                                    text="signup_with"
                                />

                                <div className="relative flex items-center justify-center my-6">
                                    <div className="border-t border-gray-300 w-full absolute"></div>
                                    <span className="bg-white px-4 text-sm text-gray-500 relative">or</span>
                                </div>

                                <button
                                    onClick={handleEmailSignUp}
                                    className="w-full flex items-center justify-center gap-3 px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
                                >
                                    Register with Email
                                </button>
                            </div>
                        ) : (
                            <>
                                <div className="flex justify-center gap-2 mb-8">
                                    <div className={`w-2.5 h-2.5 rounded-full transition-colors duration-200 ${step === 1 ? 'bg-blue-600' : 'bg-gray-200'}`} />
                                    <div className={`w-2.5 h-2.5 rounded-full transition-colors duration-200 ${step === 2 ? 'bg-blue-600' : 'bg-gray-200'}`} />
                                </div>

                                <form onSubmit={handleSubmit} className="space-y-6" autoComplete="off">
                                    {step === 1 ? (
                                        <>
                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                                <div>
                                                    <input
                                                        type="text"
                                                        name="firstname"
                                                        placeholder="First Name"
                                                        value={registerFields.firstname}
                                                        onChange={handleChange}
                                                        className="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none transition-colors duration-200"
                                                        required
                                                        autoComplete="given-name"
                                                    />
                                                </div>
                                                <div>
                                                    <input
                                                        type="text"
                                                        name="surname"
                                                        placeholder="Last Name"
                                                        value={registerFields.surname}
                                                        onChange={handleChange}
                                                        className="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none transition-colors duration-200"
                                                        required
                                                        autoComplete="family-name"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email Address"
                                                    value={registerFields.email}
                                                    onChange={handleChange}
                                                    className="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none transition-colors duration-200"
                                                    required
                                                    autoComplete="email"
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                <input
                                                    type="text"
                                                    name="shop_name"
                                                    placeholder="Business Name"
                                                    value={registerFields.shop_name}
                                                    onChange={handleChange}
                                                    className="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none transition-colors duration-200"
                                                    required
                                                    autoComplete="off"
                                                    data-form-type="other"
                                                />
                                            </div>
                                            <div>
                                                <select
                                                    name="currency"
                                                    value={registerFields.currency}
                                                    onChange={handleChange}
                                                    className="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none transition-colors duration-200"
                                                    autoComplete="off"
                                                >
                                                    {currencies.map((currency: any, currencyIdx: number) => (
                                                        <option value={currency.cc} key={currency.cc}>{currency.name} ({currency.symbol})</option>
                                                    ))}
                                                    <option value="USD">US Dollar ($)</option>
                                                    <option value="EUR">Euro (€)</option>
                                                    <option value="GBP">British Pound (£)</option>
                                                </select>
                                            </div>
                                            <div>
                                                <input
                                                    type="password"
                                                    name="password"
                                                    placeholder="Create Password"
                                                    value={registerFields.password}
                                                    onChange={handleChange}
                                                    className="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none transition-colors duration-200"
                                                    required
                                                    autoComplete="new-password"
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    type="password"
                                                    name="confirmPassword"
                                                    placeholder="Confirm Password"
                                                    value={registerFields.confirmPassword}
                                                    onChange={handleChange}
                                                    className="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none transition-colors duration-200"
                                                    required
                                                    autoComplete="new-password"
                                                />
                                            </div>
                                        </>
                                    )}

                                    <button
                                        type="submit"
                                        disabled={isPosting}
                                        className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg font-medium hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-200 transition-colors duration-200 disabled:bg-gray-400 disabled:cursor-not-allowed"
                                    >
                                        {isPosting ? 'Creating Account...' :
                                            step === 1 ? 'Continue' : 'Create Account'}
                                    </button>
                                </form>
                            </>
                        )}

                        <p className="mt-6 text-center text-gray-600">
                            Already have an account?{' '}
                            <Link to="/login" className="text-blue-600 hover:text-blue-800 font-medium">
                                Sign in
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;