import { OrderInterface, OrderProductInterface, ProductInterface, SidePanelInterface } from "../types";
import SidePanel from "./SidePanel";
import React, { useEffect, useState } from "react";
import OrderDetails from "./orders/OrderDetails";
import EditOrderForm from "./orders/EditOrderForm";
import { Button } from "./SharedComponents";
import apiClient from "../services/apiClient";
import { Box, CircularProgress, Switch } from "@mui/material";
import notify from "../services/notify";
import ReactGA from "react-ga4";

interface OrderModal extends Omit<SidePanelInterface, 'children'> {
    order?: OrderInterface | null,
    handleHidePanel: (order?: OrderInterface | null) => void
};

const fetchOrderProducts = (orderID: number) => {
    return apiClient.get(`/orders/${orderID}/variants`);
}

const fetchOrderInvoice = (orderID: number) => {
    return apiClient.get(`/orders/generate-invoice/${orderID}`, {
        responseType: 'blob', // Important
    });
}

export default function OrderModal(props: OrderModal) {
    const { order, handleHidePanel, ...sidePanelProps } = props;

    const [isOrderPaymentStatusLoading, setIsOrderPaymentStatusLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<OrderProductInterface[]>([]);
    const [totalOrderPrice, setTotalOrderPrice] = useState<number | null>(null);
    const [isOrderProductsLoading, setIsOrderProductsLoading] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isOrderPaid, setIsOrderPaid] = useState<boolean>(false);
    const [isDownloadingInvoice, setIsDownloadingInvoice] = useState<boolean>(false);

    useEffect(() => {
        if (order && order.id) {
            setIsOrderPaid(order.is_order_paid);
            ReactGA.event({
                category: "Order",
                action: "Viewed Order",
                label: `Order ID: ${order.id}`
            });
        }
    }, [order])

    useEffect(() => {
        if (order && order.id) {
            setIsOrderProductsLoading(true);
            fetchOrderProducts(order.id).then((response: any) => {
                setIsOrderProductsLoading(false);
                setProducts(response.data.variants)
                setTotalOrderPrice(response.data.order_price_total)
            }).catch((error: any) => {
                setIsOrderProductsLoading(false);
                notify("error", "Error fetching order products");
                console.error("Error fetching order products", error);
            });
        } else {
            setIsEditing(true)
        }
    }, [])

    const handleOrderPaymentStatus = () => {
        setIsOrderPaymentStatusLoading(true);
        if (order) {
            apiClient.update(`/orders/update-payment-status/${order.id}`, { is_paid: !isOrderPaid }).then((response: any) => {
                setIsOrderPaid(!isOrderPaid);
                notify("info", "Payment status updated");
                hidePanel({
                    ...order,
                    is_order_paid: !isOrderPaid
                });
                console.log("payment status updated", response.data);
                ReactGA.event({
                    category: "Order",
                    action: "Updated Payment Status",
                    label: `Order ID: ${order.id}`,
                    value: !isOrderPaid ? 1 : 0 // 1 for paid, 0 for unpaid
                });
            }).catch((error: any) => {
                notify("error", "Error updating payment status");
                console.error("Error updating payment status", error);
                ReactGA.event({
                    category: "Order",
                    action: "Failed to Update Payment Status",
                    label: `Order ID: ${order.id}`
                });
            }).finally(() => {
                setIsOrderPaymentStatusLoading(false);
            })
        }
    }

    const downloadInvoice = async (orderId?: number) => {
        if (!orderId) return;

        setIsDownloadingInvoice(true);

        fetchOrderInvoice(orderId).then((response: any) => {

            const url = window.URL.createObjectURL(new Blob([response]));

            // Create a link element to download the file
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `invoice_${orderId}.pdf`); // Set the filename
            document.body.appendChild(link);
            link.click();

            // Cleanup
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            notify("info", "Invoice generated successfully");

            ReactGA.event({
                category: "Order",
                action: "Generated Invoice",
                label: `Order ID: ${orderId}`
            });

        }).catch((error: any) => {
            notify("error", "Error downloading invoice");
            console.error("Error downloading invoice", error);
            ReactGA.event({
                category: "Order",
                action: "Failed to Generate Invoice",
                label: `Order ID: ${orderId}`
            });
        }).finally(() => {
            setIsDownloadingInvoice(false);
        })
    };

    const hidePanel = (order: OrderInterface | null = null) => {
        setProducts([]);
        setTotalOrderPrice(null);
        // setIsEditing(false);
        if (order) {
            handleHidePanel(order);
        } else {
            handleHidePanel();
        }
        ReactGA.event({
            category: "User Interaction",
            action: "Closed Order Panel",
            label: order ? `Order ID: ${order.id}` : "No Order"
        });
    }

    const handleEditOrderClick = () => {
        ReactGA.event({
            category: "User Interaction",
            action: "Edit Order",
            label: order ? `Order ID: ${order.id}` : "No Order"
        });

        setIsEditing(true)
    }

    return (
        <SidePanel {...sidePanelProps} handleHidePanel={() => hidePanel()}>
            {
                isEditing ? (
                    <EditOrderForm order={order} products={products} handleHidePanel={hidePanel} isOrderProductsLoading={isOrderProductsLoading} cancelEdit={() => setIsEditing(false)} />
                ) : (
                    <>
                        <OrderDetails order={order} isOrderProductsLoading={isOrderProductsLoading} products={products} totalOrderPrice={totalOrderPrice} />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '50px' }}>
                            <p>Mark Order As Paid</p>
                            {
                                isOrderPaymentStatusLoading ? <CircularProgress /> : (
                                    <Switch checked={isOrderPaid} onChange={() => handleOrderPaymentStatus()} />
                                )
                            }
                        </Box>
                        {
                            order && (
                                <Button disabled={isDownloadingInvoice} onClick={() => downloadInvoice(order.id)}>
                                    {
                                        isDownloadingInvoice ? <CircularProgress size={15} color="inherit" /> : "Download Invoice"
                                    }
                                </Button>
                            )
                        }
                        <Button onClick={handleEditOrderClick}>Edit Order</Button>
                    </>
                )
            }
        </SidePanel>
    )
}