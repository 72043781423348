import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import * as routes from '../utils/routes';
import apiClient from '../services/apiClient';
import ReactGA from 'react-ga4';

const Login = () => {
	const [isEmailLogin, setIsEmailLogin] = useState(false);
	const [isPosting, setIsPosting] = useState(false);
	const [loginFields, setLoginFields] = useState({
		email: '',
		password: ''
	});

	const navigate = useNavigate();

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setLoginFields({ ...loginFields, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsPosting(true);
		apiClient.auth.login(loginFields)
			.then(() => {
				setIsPosting(false);
				navigate(routes.DASHBOARD);
			})
			.catch(() => {
				setIsPosting(false);
			});
	};

	const handleGoogleLogin = (response: any) => {
		apiClient.auth.loginViaGoogle(response)
			.then(() => {
				navigate(routes.DASHBOARD);
			});
	};

	const handleEmailLoginClick = () => {
		setIsEmailLogin(true);
	};

	React.useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/login",
			title: "Login",
		});
	}, []);

	const benefits = [
		{ icon: '📊', text: 'Track inventory in real-time' },
		{ icon: '💫', text: 'Streamline your business operations' },
		{ icon: '📱', text: 'Access from any device, anywhere' },
		{ icon: '🔄', text: 'Automatic sync across all platforms' }
	];

	return (
		<div className="min-h-screen bg-gradient-to-br from-blue-50 to-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
			<div className="absolute top-8 left-8 md:fixed">
				<Link to="/" className="flex items-center gap-2">
					{/* <div className="bg-blue-600 text-white w-8 h-8 md:w-10 md:h-10 rounded-lg flex items-center justify-center font-bold text-lg md:text-xl">V</div> */}
					<span className="text-xl md:text-2xl font-bold text-gray-900" style={{ fontSize: '1.8rem' }}>Vend<span style={{ color: '#3a5bd9' }}>Ready</span></span>
				</Link>
			</div>

			<div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-center gap-1 lg:gap-12 md:mt-20 md:mt-0" style={{ width: '100%' }}>
				<div className="lg:w-1/2 hidden md:block " >
					<h1 className="text-3xl md:text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl mb-6 text-center md:text-left">
						Welcome Back!
					</h1>
					<p className="text-lg md:text-xl text-gray-600 mb-0 md:mb-12 text-center md:text-left">
						Log in to continue managing your inventory and growing your business.
					</p>

					<div className="space-y-4 hidden md:block">
						{benefits.map((benefit, index) => (
							<div
								key={index}
								className="flex items-center gap-4 p-4 bg-white/80 rounded-xl shadow-sm hover:translate-x-1 transition-transform duration-200"
							>
								<div className="w-10 h-10 md:w-12 md:h-12 flex items-center justify-center bg-blue-50 rounded-full flex-shrink-0">
									<span className="text-xl md:text-2xl">{benefit.icon}</span>
								</div>
								<span className="text-gray-700">{benefit.text}</span>
							</div>
						))}
					</div>
				</div>

				<div className="lg:w-1/2 w-full">
					<div className="md:bg-white md:shadow-xl shadow-none rounded-2xl p-6 sm:p-8 md:p-12 w-full max-w-xl mx-auto">
						<h2 className="text-center text-2xl md:text-3xl font-bold text-gray-900 mb-2">
							Sign In to VendReady
						</h2>
						<p className="text-center text-gray-600 mb-8">
							Access your business dashboard
						</p>

						{!isEmailLogin ? (
							<div className="space-y-4" style={{ width: '350px', margin: '0px auto' }}>
								<GoogleLogin
									width={350}
									onSuccess={handleGoogleLogin}
									onError={() => {
										console.log("login failed");
									}}
									text="signin_with"
								/>

								<div className="relative flex items-center justify-center my-6">
									<div className="border-t border-gray-300 w-full absolute"></div>
									<span className="bg-white px-4 text-sm text-gray-500 relative">or</span>
								</div>

								<button
									onClick={handleEmailLoginClick}
									className="w-full flex items-center justify-center gap-3 px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
								>
									Continue with Email
								</button>
							</div>
						) : (
							<form onSubmit={handleSubmit} className="space-y-6">
								<div>
									<input
										type="email"
										name="email"
										placeholder="Email Address"
										value={loginFields.email}
										onChange={handleChange}
										className="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none transition-colors duration-200"
										required
										autoComplete="email"
									/>
								</div>
								<div>
									<input
										type="password"
										name="password"
										placeholder="Password"
										value={loginFields.password}
										onChange={handleChange}
										className="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none transition-colors duration-200"
										required
										autoComplete="current-password"
									/>
								</div>

								<div className="flex justify-end">
									<Link to={routes.FORGOTTEN_PASSWORD} className="text-sm text-blue-600 hover:text-blue-800">
										Forgot password?
									</Link>
								</div>

								<button
									type="submit"
									disabled={isPosting}
									className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg font-medium hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-200 transition-colors duration-200 disabled:bg-gray-400 disabled:cursor-not-allowed"
								>
									{isPosting ? 'Signing in...' : 'Sign In'}
								</button>
							</form>
						)}

						<p className="mt-6 text-center text-gray-600">
							Don't have an account?{' '}
							<Link to={routes.REGISTER} className="text-blue-600 hover:text-blue-800 font-medium">
								Sign up
							</Link>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;