import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../types';
import * as routes from '../utils/routes';

const PrivateRoutes: React.FC = () => {
    const { token, is_email_verified, email } = useSelector((state: RootState) => ({
        token: state.auth?.user?.token,
        is_email_verified: state.auth?.user?.is_email_verified,
        email: state.auth?.user?.email,
    }));

    if (!token) {
        return <Navigate to={routes.LOGIN} />;
    }

    if (!is_email_verified) {
        return <Navigate to={`${routes.VERIFY_EMAIL}?email=${email}`} />;
    }

    return <Outlet />;
};

export default PrivateRoutes;