import { useSelector } from "react-redux";
import { RootState } from "../types";
import currencies from '../utils/currencies.json';
import store from '../redux/store';
import { CurrencyCode } from "../types/currencies";

export const getCurrency = (currencyCode: CurrencyCode) => {
    // Find the currency object based on the currency code
    const currency = currencies.find((currency: any) => currency.cc === currencyCode);

    const currencySymbol = currency?.symbol;

    return currencySymbol;
}