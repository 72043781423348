import React from 'react';
import { Link } from 'react-router-dom';
import * as routes from '../utils/routes';
import "../landing-page.css";

export function LandingPage() {
	return (
		<span style={{ fontFamily: 'Poppins, sans-serif' }}>
			<header>
				<div className="container">
					<nav>
						<div className="logo">Vend<span>Ready</span></div>
						<div className="nav-links">
							<Link to={routes.LOGIN} className="btn btn-outline">Login</Link>
						</div>
					</nav>
				</div>
			</header>

			<section className="hero">
				<div className="container">
					<div className="hero">
						<div className="hero-content">
							<h1>Take Control of Your Inventory <br />& Orders</h1>
							<p className="subtitle">
								VendReady is built for growing businesses, Instagram & WhatsApp sellers who struggle with managing orders and tracking stock. Stay organized, save time, and never lose a sale again.
							</p>
							<Link to={routes.REGISTER} className="btn">Get Started for Free</Link>
							<Link to={routes.REGISTER} className="btn btn-outline" style={{ marginLeft: '1rem' }}>See Demo</Link>
						</div>
						<div className="hero-image" style={{ width: '70%' }}>
							<img style={{ width: '100%' }} src="landing-page/hero-mockup.png" alt="VendReady Dashboard" />
						</div>
					</div>
				</div>
			</section>

			<section id="features" className="features">
				<div className="container">
					<h2 className="section-title">Perfect for Instagram & WhatsApp Sellers</h2>
					<p className="section-subtitle">No more lost orders or out-of-stock surprises. VendReady makes order tracking and inventory management easy.</p>
					{/* Feature 1: Left text, right image */}
					<div className="feature-row">
						<div className="feature-content">
							<h3 className="feature-title">Smart Inventory Management</h3>
							<p>Keep track of your entire product catalog with ease. Set minimum stock thresholds and receive automatic alerts when inventory runs low. Our intuitive dashboard shows you stock levels at a glance, so you'll never miss a restock opportunity.</p>
						</div>
						<div className="feature-image">
							<img src="landing-page/smart-inventory-management.png" alt="VendReady Inventory Management Interface" />
						</div>
					</div>

					{/* Feature 2: Right text, left image */}
					<div className="feature-row reverse">
						<div className="feature-image">
							<img className="feature-image-order-processing" src="landing-page/order-processing-vendready.png" alt="VendReady Order Processing Interface" />
						</div>
						<div className="feature-content">
							<h3 className="feature-title">Seamless Order Processing</h3>
							<p>Take customer orders directly through the platform. Track payment status, order fulfillment, and delivery all in one place. Send automated notifications to customers about their order status and create professional invoices with just a few clicks.</p>
						</div>
					</div>

					{/* Feature 3: Left text, right image */}
					<div className="feature-row">
						<div className="feature-content">
							<h3 className="feature-title">Financial Insights</h3>
							<p>Get a complete picture of your business finances. Track expenses, revenue, and profit margins. Generate detailed reports to identify your top-selling products, busiest periods, and areas for improvement. Make data-driven decisions with powerful analytics dashboards.</p>
						</div>
						<div className="feature-image">
							<img src="landing-page/analytics.png" alt="VendReady Financial Analytics Dashboard" />
						</div>
					</div>

					{/* Feature 4: Right text, left image */}
					<div className="feature-row reverse">
						<div className="feature-image">
							<img src="landing-page/vendready-mobile-desktop-responsive.png" alt="VendReady Automation Tools Interface" />
						</div>
						<div className="feature-content">
							<h3 className="feature-title">Designed For Mobile and Desktop</h3>
							<p>VendReady works perfectly on both desktop and mobile devices, allowing you to manage your business from anywhere.</p>
						</div>
					</div>
				</div>
			</section>

			<section className="testimonials">
				<div className="container">
					<h2 className="section-title">What Our Users Say</h2>
					<p className="section-subtitle">VendReady has helped hundreds of businesses streamline their operations. Here's what some of them have to say.</p>

					<div className="testimonials-grid">
						<div className="testimonial-card">
							<div className="testimonial-content">
								<p className="testimonial-text">"Running a small skincare brand, I used to rely on messy spreadsheets and notes to track my stock. Now, everything is in one place, and I get alerts before anything runs out. It's saved me time, money, and a lot of frustration!"</p>
								<div className="testimonial-author">
									{/* <img src="landing-page/testimonial-1.jpg" alt="Sofia Chen" className="testimonial-avatar" /> */}
									<div className="testimonial-info">
										<h4>Maame Afua Konadu</h4>
										{/* <p>Natural Skincare Brand Owner</p> */}
									</div>
								</div>
							</div>
						</div>

						<div className="testimonial-card">
							<div className="testimonial-content">
								<p className="testimonial-text">"We used to throw out so many expired products because we didn't have a proper tracking system. Since using VendReady, we’ve cut down waste dramatically and always have the right amount of stock on hand. It's a no-brainer for any food business!"</p>
								<div className="testimonial-author">
									{/* <img src="landing-page/testimonial-2.jpg" alt="Marcus Johnson" className="testimonial-avatar" /> */}
									<div className="testimonial-info">
										<h4>Eseosa Oshodin</h4>
										{/* <p>Food Processing Business Owner</p> */}
									</div>
								</div>
							</div>
						</div>

						<div className="testimonial-card">
							<div className="testimonial-content">
								<p className="testimonial-text">"As someone who sells exclusively through Instagram, VendReady has been a game-changer. I can manage everything from my phone while still creating content."</p>
								<div className="testimonial-author">
									{/* <img src="landing-page/testimonial-3.jpg" alt="Aisha Patel" className="testimonial-avatar" /> */}
									<div className="testimonial-info">
										<h4>Janice Asare</h4>
										{/* <p>Handmade Jewelry Creator</p> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <section id="pricing" className="pricing">
				<div className="container">
					<h2 className="section-title">Simple, Transparent Pricing</h2>
					<p className="section-subtitle">Choose the plan that works best for your business needs</p>

					<div className="pricing-grid">
						<div className="pricing-card">
							<div className="pricing-header">
								<h3 className="pricing-title">Free</h3>
								<div className="pricing-price">
									<span className="price">$0</span>
									<span className="period">/month</span>
								</div>
								<p className="pricing-subtitle">Perfect for getting started</p>
							</div>
							<div className="pricing-features">
								<ul>
									<li>Up to 50 products</li>
									<li>Unlimited Orders</li>
									<li>Inventory Stocking History</li>
									<li>CSV Export</li>
									<li>Invoice Download</li>
								</ul>
							</div>
							<div className="pricing-action">
								<Link to={routes.REGISTER} className="btn btn-outline">Get Started</Link>
							</div>
						</div>

						<div className="pricing-card featured">
							<div className="pricing-badge-wrapper">
								<div className="pricing-badge">Best Value</div>
							</div>
							<div className="pricing-header">
								<h3 className="pricing-title">Premium</h3>
								<div className="pricing-price">
									<span className="price">$9.99</span>
									<span className="period">/month</span>
								</div>
								<p className="pricing-subtitle">For growing businesses</p>
							</div>
							<div className="pricing-features">
								<ul>
									<li>Unlimited Products</li>
									<li>Unlimited Orders</li>
									<li>Inventory Stocking History</li>
									<li>CSV Export</li>
									<li>Automated emailing of Invoices and Reciepts</li>
									<li>5 stores and 5 accounts</li>
									<li>Financial reports & analytics</li>
									<li>Storefront with a custom domain</li>
									<li>20,000 image upload</li>
								</ul>
							</div>
							<div className="pricing-action">
								<Link to={routes.REGISTER} className="btn">Get Started</Link>
							</div>
						</div>
					</div>
				</div>
			</section> */}

			<section className="cta">
				<div className="container">
					<h2>Ready to Streamline Your Business Operations?</h2>
					<p>Join our business owners who have simplified their inventory and order management with VendReady.</p>
					<Link to={routes.REGISTER} className="btn">Create Your Free Account Today</Link>
				</div>
			</section>

			<footer>
				<div className="container">
					<div className="footer-content">
						<div className="footer-info">
							<div className="footer-logo">Vend<span>Ready</span></div>
							<p>The complete inventory and order management solution for growing businesses.</p>
						</div>
					</div>
					<div className="copyright">
						&copy; 2025 VendReady. All rights reserved.
					</div>
				</div>
			</footer>
		</span>
	);
}