import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import * as routes from '../utils/routes';
import apiClient from '../services/apiClient';
import notify from '../services/notify';
import { CircularProgress } from '@mui/material';
import ReactGA from 'react-ga4';

const postPasswordResetRequest = (email: string) => {
    return apiClient.get(`/users/request-password-reset?email=${email}`);
}

export default function ForgottenPassword() {

    const [email, setEmail] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/forgotten-password",
            title: "Forgotten Password",
        });
    }, []);



    const requestPasswordReset = () => {
        setIsLoading(true);
        postPasswordResetRequest(email)
            .then((response) => {
                notify("info", "Password reset instructions sent to your email");
                navigate(routes.LOGIN);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-white flex items-center justify-center py-12 px-4">
            <div className="absolute top-8 left-8">
                <div className="flex items-center gap-2">
                    <div className="bg-blue-600 text-white w-10 h-10 rounded-lg flex items-center justify-center font-bold text-xl">V</div>
                    <span className="text-2xl font-bold text-gray-900">VendReady</span>
                </div>
            </div>

            <div className="w-full max-w-xl">
                <div className="bg-white rounded-2xl shadow-xl p-12">
                    <h2 className="text-center text-3xl font-bold text-gray-900 mb-2">
                        Forgot Your Password?
                    </h2>
                    <p className="text-center text-gray-600 mb-8">
                        Enter your email address and we'll send you instructions to reset your password.
                    </p>

                    <form className="space-y-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Email Address
                            </label>
                            <input
                                type="email"
                                name="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={handleChange}
                                className="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none transition-colors duration-200"
                                required
                            />
                        </div>

                        <button
                            type="button"
                            onClick={requestPasswordReset}
                            disabled={isLoading}
                            className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg font-medium hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-200 transition-colors duration-200 disabled:bg-gray-400 disabled:cursor-not-allowed"
                        >
                            {isLoading ? 'Sending Instructions...' : 'Send Reset Instructions'}
                        </button>

                        <div className="text-center">
                            <Link
                                type="button"
                                onClick={() => console.log('Navigate to login')}
                                className="text-blue-600 hover:text-blue-800 font-medium" to={routes.LOGIN}                            >
                                Back to Login
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}