import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import apiClient from '../services/apiClient';
import { useLocation, useNavigate } from 'react-router-dom';
import * as routes from '../utils/routes';
import ReactGA from 'react-ga4';
import notify from '../services/notify';
import { Button, FormGroup, InputField, Label } from '../components/SharedComponents';
import { useSelector } from 'react-redux';
import { RootState } from '../types';

const Wrapper = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    justify-content: center;
    padding: 30px;
`;

const Logo = styled.p`
    font-size: 30px;
    font-family: 'Noto Sans', sans-serif;
    font-family: 'Red Hat Display', sans-serif;
    margin-bottom: 50px;
`;

const Title = styled.h1`
    font-size: 30px;
    margin-bottom: 20px;
    text-align: center;
`;

const postEmailVerification = (token: string, email: string) => {
    return apiClient.get(`/users/verify-email?email=${email}&token=${token}`);
}

const postSendEmailVerificationRequest = (email: string) => {
    return apiClient.get(`/users/send-verification-email?email=${email}`);
}

export default function VerifyEmail() {
    const [isVerificationFailed, setIsVerificationFailed] = React.useState<boolean>(false);
    const [emailVerificationWaitTime, setEmailVerificationWaitTime] = React.useState<number>(0);
    const [email, setEmail] = React.useState<string>("")

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const paramEmail = searchParams.get('email');

    const is_email_verified = useSelector((state: RootState) => state.auth.user?.is_email_verified);
    const authToken = useSelector((state: RootState) => state.auth.user?.token);

    const navigate = useNavigate();

    useEffect(() => {
        if (is_email_verified && authToken) {
            navigate(routes.DASHBOARD);
        }

        ReactGA.send({
            hitType: "pageview",
            page: `/verify-email?token=${token}&email=${email}`,
            title: "Verify Email",
        });
        initializeEmailVerification();
    }, []);

    useEffect(() => {
        if (paramEmail)
            setEmail(paramEmail)
    }, [paramEmail])

    const initializeEmailVerification = () => {
        if (token && paramEmail) {
            setIsVerificationFailed(false)

            postEmailVerification(token, paramEmail)
                .then((response) => {
                    notify("success", "Email Verified Successfully!");
                    console.log(response);
                    navigate(routes.LOGIN);
                    ReactGA.event({
                        category: "Email Verification",
                        action: "Email Verified",
                        label: `Email: ${paramEmail}`
                    });
                })
                .catch((error) => {
                    notify("error", "Failed to verify email. Please check email and try again", error)
                    setIsVerificationFailed(true)
                    console.log(error);
                    ReactGA.event({
                        category: "Email Verification",
                        action: "Email Verification Failed",
                        label: `Email: ${paramEmail}`
                    });
                })
        }
    }

    const handleResendVerificationEmailClick = () => {
        if (email) {
            setEmailVerificationWaitTime(60);
            postSendEmailVerificationRequest(email).then(response => {
                notify("success", "Email verification sent successfully")
                ReactGA.event({
                    category: "Email Verification",
                    action: "Resend Verification Email",
                    label: `Email: ${email}`
                });
            }).catch((error) => {
                notify("error", "Failed to resend verification email", error);
                ReactGA.event({
                    category: "Email Verification",
                    action: "Resend Verification Email Failed",
                    label: `Email: ${email}`
                });
            });
        } else {
            notify("error", "No email set");
            navigate(routes.LOGIN)
        }
    }

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;

        if (emailVerificationWaitTime > 0) {
            intervalId = setInterval(() => {
                setEmailVerificationWaitTime((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (emailVerificationWaitTime === 0 && intervalId !== null) {
            clearInterval(intervalId);
        }

        return () => {
            if (intervalId !== null) {
                clearInterval(intervalId);
            }
        };
    }, [emailVerificationWaitTime]);

    const handleChange = (e: any) => {
        setEmail(e.target.value)
    }

    return (
        <Wrapper>
            <Logo>Vend<span style={{ color: '#3a5bd9' }}>Ready</span></Logo>
            {
                !token && (
                    <>
                        <div style={{ height: '500px', maxWidth: '600px' }}>
                            <Title>Verify Your Email</Title>
                            <div style={{ marginTop: '30px' }}>
                                <p>Didn't receive an email? Please check your email spam folder or click the button below to try to resend the verification email</p>
                                <FormGroup style={{ marginTop: '30px' }}>
                                    <Label>Email</Label>
                                    <InputField onChange={handleChange} value={email} />
                                </FormGroup>
                                <Button style={{ marginTop: '10px' }} onClick={handleResendVerificationEmailClick} disabled={emailVerificationWaitTime > 0}>
                                    {emailVerificationWaitTime > 0 ? `Resend in ${emailVerificationWaitTime}s` : 'Resend Verification Email'}
                                </Button>
                            </div>
                        </div>
                    </>
                )
            }
            {
                (token && paramEmail) && (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{ marginBottom: '30px' }}>Verifying email...</p>
                        <CircularProgress />
                    </div>
                )
            }
            {
                isVerificationFailed && (
                    <p>Failed to verify email. Please check email and try again</p>
                )
            }
        </Wrapper>
    )
}