import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './redux/store';
import { GoogleOAuthProvider } from '@react-oauth/google';

if (document.getElementById('root')) {
    const root = ReactDOM.createRoot(document.getElementById('root')!);

    const clientId: string | undefined = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

    root.render(
        <React.StrictMode>
            <GoogleOAuthProvider clientId={clientId ?? ""} >
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <App />
                    </PersistGate>
                </Provider>
            </GoogleOAuthProvider>
        </React.StrictMode>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
