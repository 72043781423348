import { Box, CircularProgress } from "@mui/material";
import { DataGrid, GridOverlay, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import "../table.css";
import { formatColumnDateFields } from "../services/dateHelper";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function CustomNoRowsOverlay() {
    return (
        <GridOverlay>
            <div className="p-5 text-center text-gray-400">
                Empty
            </div>
        </GridOverlay>
    );
}

function CustomFailedLoadingRowsOverlay() {
    return (
        <GridOverlay>
            <div className="p-5 text-center text-red-400">
                <ErrorOutlineIcon style={{ fontSize: 40, marginBottom: 10 }} />
                <div>Failed to load data</div>
            </div>
        </GridOverlay>
    );
}

function CustomLoadingRowsOverlay() {
    return (
        <GridOverlay>
            <div className="p-5 text-center text-gray-400">
                <CircularProgress />
                <div>Loading...</div>
            </div>
        </GridOverlay>
    );
}

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarExport />
        </GridToolbarContainer>
    );
};

export default function Table(props: any) {
    const { objects, onRowClick } = props;
    let { customState = {}, columns, emptyStateComponent, fetchState } = props;

    columns = formatColumnDateFields(columns);

    const renderCellContent = (column: any, object: any) => {
        if (column.renderCell) {
            // Call renderCell function if defined
            return column.renderCell({ row: object, value: object[column.field] });
        } else {
            // Otherwise return the normal field value
            return object[column.field];
        }
    };

    const setEmptyStateComponent = (component: any, objects: any[]) => {
        switch (fetchState) {
            case 'loading':
                return CustomLoadingRowsOverlay;
            case 'failed':
                return CustomFailedLoadingRowsOverlay;
            case 'success':
                return objects.length === 0 ? (component ?? CustomNoRowsOverlay) : () => null;
            default:
                return CustomNoRowsOverlay;
        }
    }

    const initialState = {
        pagination: { paginationModel: { pageSize: 25 } },
        ...customState,
    }

    return (
        <>
            <Box className=" h-[82vh] bg-white " sx={{ borderRadius: '10px', padding: '0px 20px', '@media(max-width:768px)': { padding: '0px 10px' } }}>
                <DataGrid
                    initialState={initialState}
                    disableSelectionOnClick={true}
                    getCellClassName={(params) =>
                        'table-cell ' + (['name', 'variant_name', 'customer_name', 'title'].includes(params.field)
                            ? 'font-sans p-6 font-medium text-gray-900 whitespace-nowrap'
                            : 'font-sans px-6 py-4 text-gray-500 whitespace-nowrap')
                    }
                    getRowClassName={(params) => `table-row`}
                    rows={objects}
                    columns={columns}
                    pageSizeOptions={[25, 50, 100]}
                    className="h-full w-full custom-table"
                    rowHeight={50}
                    columnHeaderHeight={50}
                    onRowClick={onRowClick} // Handle row click for the table
                    slots={{ toolbar: CustomToolbar, noRowsOverlay: setEmptyStateComponent(emptyStateComponent, objects) }}
                    slotProps={{
                        columnHeaders: {
                            className: 'table-header'
                        }
                    }}
                    {...props}
                />
            </Box>
        </>
    );
}
